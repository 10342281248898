import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Button from "~/components/button";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={8}
        lesson={"Lesson 1"}
        color={"ms"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 8</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>Take a few minutes to assign an overall score for each of the 3 websites that you reviewed. The scoring guidelines are at the end of the rubric.</p>
              <p>If you want to look at them again, click the buttons below.</p>

              <div className="flex flex-col lg:flex-row">
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="purple"
                  href="/article/safe-environment"
                  icon="external"
                  title="Article 1 Lesson 1"
                >
                  Website 1
                </Button>
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="purple"
                  href="/article/active-life"
                  icon="external"
                  title="Article 2 Lesson 1"
                >
                  Website 2
                </Button>
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="purple"
                  href="/article/lung-health"
                  icon="external"
                  title="Article 3 Lesson 1"
                >
                  Website 3
                </Button>
              </div>

              <p>Remember, you are just thinking about the "Website Quality Initial Screen" category of the rubric. For each website, you will have 1 score for this section of your rubric.</p>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Allow 5-10 minutes for students to assign scores on their rubrics and to discuss their scores with their teammates. The scoring guidelines are at the end of the rubric.</p>
              <p>Click to see a summary of the features of the 3 websites that students evaluated and scored using the rubric.</p>

              <div className="flex flex-col lg:flex-row">
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="purple"
                  href="/files/hs/lesson_1/ms_web_features_summary.pdf"
                  icon="download"
                  title="Website Features Summary"
                >
                Website features summary
                </Button>
              </div>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
